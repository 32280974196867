<template>
	<div class="section_wrap pt-50 pb-30">
		<div class="container">
			<div class="row">
				<!-- 카드 소개 -->
				<div class="pt-30 pb-30">
					<div class="main_card_wrap">
						<div class="main_card">
							<figure class="nft-card-info">
								<div
									class="o_square"
								>
									<div class="inner">
										<div class="frame">
											<div
												v-if="item.is_staking"
												class="lock"
											>
												<img
													:src="$request.upload_url(item.cartl_nft_img_url ? item.cartl_nft_img_url : item.nft_card_img_url)"
													:alt="item.nft_card_name"
												/>
											</div>
											<img
												v-else
												:src="$request.upload_url(item.cartl_nft_img_url ? item.cartl_nft_img_url : item.nft_card_img_url)"
												:alt="item.nft_card_name"
											/>
										</div>
									</div>
								</div>
								<figcaption>
									<strong>{{  item.cartl_nft_kind_name ? item.cartl_nft_kind_name : item.nft_card_name  }}</strong>
									<p>{{  item.cartl_nft_desctn ? item.cartl_nft_desctn : item.nft_card_desctn }}</p>
								</figcaption>
							</figure>
							<div class="card_badge">
								<span class="badge_40 " :class="'badge_40_' + item.type "><em class="hide">music</em></span>
								<span class="badge_40 badge_40_capo hide"><em class="hide">capo</em></span>
							</div>
						</div>
					</div>
				</div>
				<!-- //카드 소개 -->
				<!-- 카드 정보 -->
				<div class="cont_box cont_box_type1">
					<div class="box_tit">
						<h2>{{  $language.nft.nft_info }}</h2>
					</div>
					<div class="box_detail">
						<dl>
							<dt>{{  $language.nft.holder }}</dt>
							<dd>{{ item.owner_nickname }}</dd>
						</dl>
						<dl>
							<dt>{{  $language.nft.address_contract }}</dt>
							<dd>{{ item.smartcontract_address }}</dd>
						</dl>
						<dl>
							<dt>Token ID</dt>
							<dd>{{ item.nft_token_id }}</dd>
						</dl>
					</div>
				</div>
				<div class="cont_box cont_box_type2 mt-20">
					<div class="box_tit ar_tit">
						<h2>{{  $language.common.futures }}</h2>
						<button @click="is_021 = !is_021">
							<i class="icon icon-arrow-right"></i>
						</button>
					</div>
					<div class="box_detail">
						<table class="nft_info_table">
							<colgroup>
								<col style="width: 35%">
								<col style="width: 40%">
								<col style="width: 25%">
							</colgroup>
							<thead>
							<tr>
								<th class="t-left">{{  $language.common.futures_time }}</th>
								<th class="t-left">FROM/TO</th>
								<th class="t-right">{{  $language.common.status }}</th>
							</tr>
							</thead>
							<tbody>
							<template
								v-if="items_present_history.length > 0"
							>
							<tr
								v-for="(present, index) in items_present_history"
								:key="'present_' + index"
							>
								<td> {{ present.gift_daytime }}</td>
								<td>
									<p>{{  present.from_nickname }}</p>
									<p>{{ present.to_nickname }}</p>
								</td>
								<td class="tb_ing_gray">{{  present.state_name }}</td>
							</tr>
							</template>
							<tr
								v-else
							>
								<td colspan="3" class="td_list_none">
									<div class="list_none">
										<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
										<span>{{  $language.common.Not_found_history }}</span>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="cont_box cont_box_type2 mt-20">
					<div class="box_tit ar_tit">
						<h2>{{  $language.common.transfer_history }}</h2>
						<button @click="is_022 = !is_022">
							<i class="icon icon-arrow-right"></i>
						</button>
					</div>
					<div class="box_detail">
						<table class="nft_info_table">
							<colgroup>
								<col style="width: 35%">
								<col style="width: 40%">
								<col style="width: 25%">
							</colgroup>
							<thead>
							<tr>
								<th class="t-left">{{  $language.common.transfer_time }}</th>
								<th class="t-left">{{  $language.common.transfer_ID }}</th>
								<th class="t-right">{{  $language.common.status }}</th>
							</tr>
							</thead>
							<tbody>
								<template
									v-if="items_history.length > 0"
								>
									<template
										v-for="(history, index) in items_history"
									>
										<tr
											:key="'history_' + index"
										>
											<td> {{ history.deptwidal_daytime }}</td>
											<td class="tb_ing_gray">{{  history.state_name }}</td>
										</tr>
										<tr
											:key="'history2_' + index"
										>
											<td>
												<p>{{  history.transaction_id  }}</p>
											</td>
										</tr>
									</template>
								</template>
								<tr
									v-else
								>
									<td colspan="3" class="td_list_none">
										<div class="list_none">
											<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
											<span>{{  $language.common.Not_found_history }}</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<!-- //카드 정보 -->
			</div>
		</div>

		<mafia021
			v-if="is_021"

			:user="user"
			:card="item"

			@click="is_021 = !is_021"
		></mafia021>

		<mafia022
			v-if="is_022"

			:user="user"
			:card="item"

			@click="is_022 = !is_022"
		></mafia022>
	</div>
</template>


<script>

import mafia021 from "@/view/Nft/mafia021";
import mafia022 from "@/view/Nft/mafia022";
export default{
	name: 'mafia020'
	, props: ['Axios', 'user']
	, components: { mafia021, mafia022 }
	, data: function(){
		return {
			program: {
				name: this.$language.common.mafia_card_detail
				, type: 'popup'
				, title: 'MAFIA NFT'
			}
			, is_021: false
			, is_022: false
			, id: this.$route.params.id
			, item: {
				nft_card_number: ''
			}
			, items_history: []
			, items_present_history: []
			, card_type: this.$route.params.card_type

		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_nft_detail
					, data: {
						member_number: this.user.member_number
						, nft_card_number: this.$route.params.id
					}
					, type: true
				})

				if(result.success){
					this.item = result.data
					this.item.nft_card_number = this.$route.params.id
					this.items_present_history = result.data.nft_card_gift_transaction_list
					this.items_history = result.data.nft_card_transaction_list
				}else{
					this.$bus.$emit('notify',  {type: 'error', message: this.$language.common.error})
				}
			}catch (e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getNftCardInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					,url: this.$api_url.api_path.get_nft_card_info
					,data: {
						member_number: this.user.member_number
						, cartl_nft_number: this.$route.params.id
					}
					, type: true
				})

				if(result.success){
					this.item = result.data
					this.$set(this.item, 'type', (this.item.cartl_nft_catgy_name).toLowerCase())
				}else{
					this.$bus.$emit('notify',  { type: 'error', message: result.message, config: { bottom: 0}})
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: this.$language.common.error, config: { bottom: 0}})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getPresentHistory: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'get'
					, url: this.$api_url.api_path.get_present_history
					, data: {
						type: 'history_present'
					}
				})

				if(result.success){
					this.items_present_history = result.data
				}else{
					this.$bus.$emit('notify',  {type: 'error', message: this.$language.common.error})
				}
			}catch (e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getDepoistHistory: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'get'
					, url: this.$api_url.api_path.get_history
					, data: {
						type: 'history'
					}
				})

				if(result.success){
					this.items_history = result.data
				}else{
					this.$bus.$emit('notify',  {type: 'error', message: this.$language.common.error})
				}
			}catch (e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)

		if(this.card_type == 'nft') {
			this.getNftCardInfo()
		}else{
			this.getData()
		}
		//this.getDepoistHistory()
		//this.getPresentHistory()
	}
}

</script>
